import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar';
import Aux from '../../../hoc/hoc';
import { ToastsStore } from 'react-toasts';


class CreateNote extends Component {
    constructor() {
        super();
        this.state = {
            elements: [],
            sel: null,
            quoteModel: {},
            noteData: {}
        }
        this.showHeaderDate = true;
        this.disabBtn = 1;
        this.defWwight = ['1–2', '2–3', '3–4', '4–5', '5–6', '6–7', '7–8', '8–9', '9+'];
        this.dayVal = [{
            dayIndex: 1,
            value: 0,
        },
        {
            dayIndex: 2,
            value: 0,
        },
        {
            dayIndex: 3,
            value: 0,
        },
        {
            dayIndex: 4,
            value: 0,
        },
        {
            dayIndex: 5,
            value: 0,
        },
        {
            dayIndex: 6,
            value: 0,
        },
        {
            dayIndex: 7,
            value: 0,
        }
        ]

    }
    componentDidMount() {
        this.baseOrderMatrix(this.defWwight)
        this.getNotes();

    }
    baseOrderMatrix(weigh) {
        const weighClass = weigh;
        const items = {};
        let model = {};
        weighClass.map((data, i) => {
            items[data] = JSON.parse(JSON.stringify(this.dayVal));
            model[data] = {
                hasVolume: 0,
                isOpen: false
            }
        })
        this.state.elements = weighClass;
        this.state.sel = items;
        this.state.quoteModel = model;
        this.forceUpdate()
        console.log(this.state.sel)
    }
    onBlur = (event, a, inx, days) => {
        if (event.target.value === "") {
            event.target.value = 0
        }
        days.volume = parseInt(event.target.value);
        this.state.sel[a][inx].value = parseInt(event.target.value);
        this.disabBtn = 0;
        this.state.quoteModel[a].isOpen = false;
        this.showHeaderDate = true;
        for (let item in this.state.quoteModel) {
            if (this.state.quoteModel[item].isOpen) this.showHeaderDate = false;
        }
        if (parseInt(event.target.value)) {
            this.state.quoteModel[a].hasVolume = true;
        }
        this.forceUpdate()
    }
    toggleAccordion = (event, key) => {
        this.state.quoteModel[key].isOpen = !this.state.quoteModel[key].isOpen
        this.showHeaderDate = !this.state.quoteModel[key].isOpen;
        this.forceUpdate();
    }
    createNote(reset = false) {
        if (this.disabBtn == 0 || reset) {
            let matrix = {}
            for (let item of this.state.noteMatrix) {
                matrix[item.key] = item.list;
                if (reset) {
                    matrix[item.key] = item.list.map(item => { item.volume = 0; return item });
                }
            }

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.token}`
            }
            // let webApiUrl = '/accumulator/api/customer-note/?customer_note_id=0'
            let webApiUrl = `/accumulator/api/customer-note/${localStorage.noteId ? parseInt(localStorage.noteId) : 0}/`
            var data = { 
                "data": matrix ,
                "access_type": localStorage.isProspect ? 'prospect' : "customer_shipment_location",
                "access_id": parseInt(localStorage.accessId)
            }
            axios.put(webApiUrl, data, {
                headers: headers
            })
                .then(response => {
                    ToastsStore.success("Note updated");
                    // window.location.href = '/home';
                    this.disabBtn = 1;
                    this.getNotes();
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            return
        }
    }

    getNotes() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/customer-note/'
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                let order = [];
                let model = {};
                for (let item in response.data.result) {
                    let temp = {};
                    temp['key'] = item;
                    temp['list'] = response.data.result[item].sort((a, b) => (a.weight_class > b.weight_class) ? 1 : ((b.weight_class > a.weight_class) ? -1 : 0));
                    order.push(temp);

                    let sum = 0;
                    response.data.result[item].forEach(element => {
                        sum += element.volume;
                    });
                    model[item] = {
                        hasVolume: sum != 0,
                        isOpen: false
                    }
                }
                this.setState({
                    elements: response.data.result,
                    noteMatrix: order,
                    noteTotalOrder: response.data.total_weight,
                    quoteModel: model,
                    noteData: response.data.customer_data
                })
                this.forceUpdate();
                console.log(this.state.noteMatrix);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    updateAnnounce() {
        this.state.noteData.is_announced = !this.state.noteData.is_announced;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/customer-note/' + this.state.noteData.id + '/announce-note/';
        let data = {
            "is_announce": this.state.noteData.is_announced
        }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.getNotes();
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error)
            })
    }


    render() {
        var daysOrder = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        return (
            <Aux>


                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        <Link to={'/home'}>
                            <p className={'Back_link'}>Back</p>
                        </Link>
                        {/* <section className={'cmn_head'}>
                            <p>Create Base Order</p>
                        </section> */}

                        <div class="scrl_out">
                            <section className={'home_top_wrap'}>
                                <div className={'home_top_block createNoteTitleWrap'}>
                                    <p>Manage <br /> Notes</p>
                                    {/* <label>ma Here</label> */}
                                    <div className={'Base_total_block'}>
                                        <span>{this.state.noteTotalOrder} {this.state.noteTotalOrder == 1 ? 'Box' : 'Boxes'}</span> <br />
                                        <label>Total Quantity</label>
                                    </div>
                                </div>
                                <div className={'home_top_block'}>
                                    <div className={'ReqBtnWrp'}>
                                        {this.state.noteData && <div className={'announce_wrap'}>
                                            <p>Announce</p>
                                            <div className={'toggle_wrap'}>
                                                <input type="checkbox" id="announce" checked={this.state.noteData.is_announced} />
                                                <label for="announce" onClick={() => this.updateAnnounce()}></label>
                                            </div>
                                        </div>}
                                        <div className={'conBt' + ' ' + 'sp_btn'} onClick={() => this.createNote(true)}>
                                            {
                                                this.state.noteTotalOrder == 0 && <div className={'disabOvl'}></div>
                                            }
                                            Reset
                                        </div>
                                        {
                                            <div className={'conBt' + ' ' + 'sp_btn'} onClick={() => this.createNote()}>
                                                {
                                                    this.disabBtn === 1 && <div className={'disabOvl'}></div>
                                                }
                                                Update
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>

                            <section className={'base_order_wrapper'}>
                                {
                                    this.showHeaderDate && <div className={'Acd_hd accordion_header'}>
                                        {
                                            this.dayVal.map((val, i) => {
                                                return <div className={'Base_days' + ' ' + 'dayBf'}>

                                                    <span className={'inData'}>

                                                        {daysOrder[i + 1]}
                                                    </span>
                                                </div>
                                            })
                                        }

                                    </div>
                                }
                                {
                                    this.state.noteMatrix && this.state.noteMatrix.map(
                                        (item, i) => {
                                            return <div className={'Base_ord_wrap' + ' ' + 'qte_bg'}>
                                                <div className={'Base_ord_head' + ' ' + 'Acd_hd'}>
                                                    <section>
                                                        <span>HOG {item.key} kg</span>
                                                    </section>
                                                    <section className={this.state.quoteModel[item.key].isOpen || !this.state.quoteModel[item.key].hasVolume ? 'hide' : ''}>
                                                        {
                                                            item.list && item.list.map((days, index) => {
                                                                return <div className={'accordion_vol'}>
                                                                    {days.volume ? days.volume : ''}
                                                                </div>
                                                            })
                                                        }
                                                    </section>
                                                    <section className={this.state.quoteModel[item.key].isOpen ? 'active' : null}>
                                                        <div className={'Accord-tog-btn'} onClick={(event) => this.toggleAccordion(event, item.key)}></div>
                                                    </section>
                                                </div>
                                                {this.state.quoteModel[item.key].isOpen && <section className={'base_order_body'}>
                                                    {
                                                        item.list && item.list.map((days, index) => {
                                                            return <div className={'Base_days ' + 'accHdLn '}>
                                                                <div className={'spn1'}>
                                                                    {daysOrder[index + 1]}
                                                                </div>

                                                                <input type="text" onBlur={(event) => this.onBlur(event, item.key, index, days)} Value={days.volume != '0' ? days.volume : ''} placeholder="0" />
                                                            </div>
                                                        })
                                                    }
                                                </section>
                                                }
                                            </div>
                                        })
                                }
                            </section>
                        </div>

                    </div>
                </section>
            </Aux>
        );
    }
};

export default CreateNote;