import React, { Component } from 'react';
import {Link, Route} from 'react-router-dom';
import Aux from '../../hoc/hoc';
import Sidebar from '../Sidebar/Sidebar';
import DashboardOverview from './DashboardOverview/Dashboard0verview';
import RequestQuote from './RequestQuote/RequestQuote';



class Home extends Component {
    state = { 
    }
   
    render() {
        return (
            <Aux>
                <section className={'body_wrap'}>   
                    <Sidebar />
                    <DashboardOverview/>     
                </section>
            </Aux>
        );
    }
};



export default Home;