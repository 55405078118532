import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import axios from 'axios';
import Modal from '../../Ui/Modal/Modal';
import CounterOffer from '../CounterOffer/CounterOffer'
import Moment from 'react-moment';
import { ToastsStore } from 'react-toasts';
import className from './BaseAccordion.css';
import { tr } from 'date-fns/locale';

class BaseAccordion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            elements: [],
            sel: null,
            updateId: null,
            countOffer: [],
            countData: {},
            countDrop: [],
            weiTotal: [],
            weiPriceTotal: [],
            per_kg_tot: [],
            priceQuote: [],
            accStat: [],
            accBase: [],
            allOfferDefault: false,
            selectedQuote: 0,
            showUnlockModal: false,
            showQuoteConfirmModal: false,
            showAcceptAllModal: false,
            countModal: false,
            showAcceptRejectModal: false,
            hasVolume: false,
            lockKey: '',
            lockIndex: '',
            nwSel: '',
            quoteModel: {},
            quoteData: {},
            enable8thDay: false,
            shipments: 0
        }
        this.unlockDays = [];
        this.counterDet = {};
        this.acceptReject = {};
        this.countStat = [];
        this.showHeaderDate = true;
        this.buildStatus = false;


        this.defWeight = ['1–2', '2–3', '3–4', '4–5', '5–6', '6–7', '7–8', '8–9', '9+'];
        this.dayVal = [{
            dayIndex: 1,
            value: 0,
            price: 0
        },
        {
            dayIndex: 2,
            value: 0,
            price: 0
        },
        {
            dayIndex: 3,
            value: 0,
            price: 0
        },
        {
            dayIndex: 4,
            value: 0,
            price: 0
        },
        {
            dayIndex: 5,
            value: 0,
            price: 0
        },
        {
            dayIndex: 6,
            value: 0,
            price: 0
        },
        {
            dayIndex: 7,
            value: 0,
            price: 0
        }
        ]
        this.weekDet = [];

    }
    componentDidMount() {
        // this.getQuoteData();
        this.getQuote();
        this.getDate();
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.eigthDay !== this.state.enable8thDay) {
            this.setState({ enable8thDay: nextProps.eigthDay });
            console.log(nextProps.eigthDay);
            this.init8thDay();
        }

        if (nextProps.shipments !== this.state.shipments) {
            this.setState({ shipments: nextProps.shipments.length });
        }
    }

    init8thDay() {
        // this.showHeaderDate = this.state.enable8thDay;
        let activeMatrix = this.state.enable8thDay ? this.state.fullMatrix : this.state.eigthDayMatrix;
        for (let item in this.state.quoteModel) { this.state.quoteModel[item].isOpen = false };
        this.setState({
            elements: Object.keys(activeMatrix),
            sel: activeMatrix,
            hasVolume: false,
            // quoteModel: model,
            // quoteData: response.data.quote
        })
    }
    counterOffer = (event, price, ext) => {
        this.counterDet.weightClass = ext;
        this.counterDet.price = price;
        this.counterDet.quoteId = localStorage.requestQuoteId;
        this.setState({
            countModal: true
        })
    }

    counterStatus = (a) => {
        if (a == 2) {
            this.setState({
                countModal: false
            })
            this.getQuote();
            ToastsStore.success("Counter offer placed");
        }
        else {
            this.setState({
                countModal: false
            })
        }
    }
    cancelOrAccept = (act, prc, weight) => {
        this.acceptReject.weight_class = weight;
        this.acceptReject.price = prc;
        this.acceptReject.action = act;
        this.setState({
            showAcceptRejectModal: true
        })
    }
    acceptCancelConfirm = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee-price-line/'
        let data = {
            "quotee_id": localStorage.requestQuoteId,
            "weight_class": this.acceptReject.weight_class,
            "price": this.acceptReject.price
        }
        if (this.acceptReject.action === 'reject') {
            data['price'] = false;
        }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.getQuote();
                this.setState({
                    showAcceptRejectModal: false
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    clsAcceptModal = () => {
        this.setState({
            showAcceptRejectModal: !this.state.showAcceptRejectModal,
        })
        this.acceptReject = {}
    }


    onBlur = (event, a, inx) => {

        if (event.target.value === "") {
            event.target.value = 0
        }
        this.state.sel[a][inx].volume = parseInt(event.target.value);
        this.state.sel[a][inx].weekday = this.state.sel[a][inx].dayIndex;
        this.state.quoteModel[a].isOpen = false;
        this.showHeaderDate = true;
        if (parseInt(event.target.value)) {
            this.state.quoteModel[a].hasVolume = true;
        }
        this.props.actBtn(1);
        this.forceUpdate();
    }


    newUser(weigh) {
        const weighClass = weigh;
        this.setState({
            nwSel: 1
        })
        const items = {};

        weighClass.map((data, i) => {
            items[data] = JSON.parse(JSON.stringify(this.dayVal))
        })


        this.state.elements = weighClass;
        this.state.sel = items;
        this.sumAccord();
        this.forceUpdate();
    }
    getDate() {
        var nextMonday = new Date();
        nextMonday.setDate(nextMonday.getDate() + (1 + 7 - nextMonday.getDay()) % 7);
        var nextWeekArrays = [nextMonday];
        for (let i = 1; i < 7; i++) {
            nextWeekArrays.push(new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i)))
        }
        this.weekDet = nextWeekArrays;
    }


    updateBaseOrder(data, action) {
        console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/update/';
        var apiData = {
            "data": data,
            'product_id': 1,
            'action': action,
            "quote_id": parseInt(localStorage.requestQuoteId),
            'is_toggled': this.state.enable8thDay != this.state.initialState
        }
        console.log(this.state.shipments)
        if (this.state.enable8thDay) {
            apiData['no_of_shipments'] = this.state.shipments;
        }
        axios.put(webApiUrl, apiData, {
            headers: headers
        })
            .then(response => {
                this.getQuote();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getQuote() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/get-full/';
        let data = {};
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                console.log(response.data);
                for (let item in response.data.active_admin_offer_full) {
                    response.data.active_admin_offer_full[item] = response.data.active_admin_offer_full[item] ? response.data.active_admin_offer_full[item] : 0
                }
                let eigthDay = false;
                let activeMatrix = response.data.full_active_quote_matrix;
                if (response.data['matrix_type'] == '8th_day') {
                    eigthDay = true;
                    activeMatrix = response.data.full_8th_day_matrix;
                }

                let model = {};
                for (let item in activeMatrix) {
                    let sum = 0;
                    activeMatrix[item].forEach(element => {
                        element.is_lock = response.data.quote.active_weekdays && response.data.quote.active_weekdays.length ? response.data.quote.active_weekdays.indexOf(element.dayIndex) === -1 : false;
                        sum += element.volume;
                    });
                    model[item] = {
                        hasVolume: sum != 0,
                        isOpen: false
                    }
                }
                console.log(response.data.active_admin_offer_full);

                this.setState({
                    elements: Object.keys(activeMatrix),
                    sel: activeMatrix,
                    fullMatrix: response.data.full_active_quote_matrix,
                    eigthDayMatrix: response.data.full_8th_day_matrix,
                    weiPriceTotal: response.data.active_admin_offer_full,
                    hasVolume: response.data.has_volumes,
                    quoteModel: model,
                    quoteData: response.data.quote,
                    enable8thDay: eigthDay,
                    initialState: eigthDay,
                    shipments: response.data.quote.no_of_shipments ? response.data.quote.no_of_shipments : response.data.quote.active_weekdays ? response.data.quote.active_weekdays.length : 0
                })

                localStorage.onBase = response.data.has_base_order;

                let offer = {};

                this.state.elements.map(
                    (key, i) => {
                        this.state.sel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                        offer[key] = response.data.negotiation ? response.data.negotiation.filter(item => item.weight_class === key)[0] : {};
                    })
                this.setState({
                    countData: offer
                })
                if (response.data.success) {
                    localStorage.setItem('requestQuoteId', response.data.quote ?.id);
                    this.props.listData(response.data);
                    this.buildStatus = response.data.build_status;
                    // this.props.buildStatusProps(this.buildStatus)
                    this.sumAccord();
                    this.isBaseLock();
                    this.props.plotDays(response.data.quote.no_of_shipments ? response.data.quote.no_of_shipments : response.data.quote.active_weekdays.length);
                    this.forceUpdate();
                    // this.props.actBtn(0);
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    getQuoteData() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_quote/';
        let data = {};
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                let dAll = Object.keys(response.data.data);
                if (dAll.length == 0) {
                    if (localStorage.onBase == 'true') {
                        this.buildStatus = true;
                        let baseOrderData = JSON.parse(localStorage.requestData);
                        this.setState({
                            elements: Object.keys(baseOrderData.data),
                            sel: baseOrderData.data,
                        })
                        if (this.state.elements.length != this.defWeight.length) {
                            this.setState({
                                nwSel: 1
                            })
                            this.defWeight.map((key, i) => {
                                if (!this.state.sel.hasOwnProperty(key)) {
                                    this.state.sel[key] = JSON.parse(JSON.stringify(this.dayVal))
                                }
                            })

                            this.state.elements = this.defWeight
                        }
                        this.sumAccord();
                        this.forceUpdate();
                    }
                    else {
                        this.props.headRequest(1);
                        this.buildStatus = false;
                        this.props.buildStatusProps(this.buildStatus)
                        this.newUser(this.defWeight)
                    }
                }
                else {
                    this.setState({
                        elements: Object.keys(response.data.data),
                        sel: response.data.data,
                    })
                }

                this.state.elements.map(
                    (key, i) => {
                        this.state.sel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                    })
                if (response.data.success) {
                    console.log("in base status")
                    localStorage.setItem('requestQuoteId', response.data.quote.id);
                    this.props.listData(response.data);
                    this.buildStatus = response.data.build_status;
                    this.props.buildStatusProps(this.buildStatus)
                    this.sumAccord();
                    this.isBaseLock();
                    this.props.plotDays(response.data.shipment_days);
                    this.forceUpdate();
                    this.props.actBtn(0);
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }


    changeText = () => {
        window.location.reload();
    }
    changeText1 = () => {
        this.updateBaseOrder(this.state.sel, 'build');
    }
    changeText2 = () => {
        if (localStorage.onBase == 'false') {
            // this.sendRequest();
            this.updateBaseOrder(this.state.sel, 'request');
        } else {
            this.updateBaseOrder(this.state.sel, 'request')
            this.setState({
                showQuoteConfirmModal: !this.state.showQuoteConfirmModal
            })
        }

    }
    changeText3 = () => {
        console.log(this.state.showAcceptAllModal)
        if (!this.state.allOfferDefault) {
            this.checkOutOrder()
        }
        else {
            this.setState({
                showAcceptAllModal: true
            })
        }
    }
    acceptAll = () => {
        this.checkOutOrder()
    }
    confirmedQuote = () => {
        this.setState({
            showQuoteConfirmModal: !this.state.showQuoteConfirmModal
        })
        // window.location.href = '/home';
    }
    checkOutOrder() {
        window.location.href = '/home/checkout';
    }

    sendRequest() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/send_request/'
        var apiData = {}
        axios.post(webApiUrl, apiData, {
            headers: headers
        })
            .then(response => {
                // this.getQuoteData();
                window.location.href = '/home';
            })
            .catch((error) => {
                console.log(error)
            })
    }

    changeShip = (a) => {
        this.getPriceQuoteItems(a);
    }
    toggleAccordion = (event, key, a) => {
        // this.state.accStat[key] = !this.state.accStat[key];
        this.state.quoteModel[key].isOpen = !this.state.quoteModel[key].isOpen
        this.forceUpdate();
        console.log(this.state.enable8thDay)
        if (!this.state.enable8thDay) this.datePanelHide();

    }
    sumAccord = () => {
        this.state.elements.map(
            (key, i) => {
                // sumCal
                var arrEl = this.state.sel[key].map(function (o, i) {
                    return o.volume;
                });
                var sum = arrEl.reduce(function (a, b) {
                    return a + b;
                }, 0);
                this.state.weiTotal[i] = sum;

                var accrStat = (sum === 0) ? true : false
                this.state.accStat[key] = accrStat;

                //showAccord
                var a = [];
                this.state.sel[key].map(function (o, i) {
                    return a.push(o.is_base ? 1 : 0);
                });
                var b = a.reduce((a, b) => a + b);
                var accrBase = (b === 0) ? true : false
                this.state.accBase[i] = accrBase;
                if (!this.state.accBase[i]) {
                    this.state.accStat[key] = this.state.accBase[i]
                }

                // priceCal
                var arrE2 = this.state.sel[key].map(function (o, i) {
                    return o.price;
                });
                var sumPrice = arrE2.reduce(function (a, b) {
                    return a + b;
                }, 0);
                // this.state.weiPriceTotal[key] = sumPrice;
                // this.state.weiPriceTotal[key] = 0;
            })
        this.datePanelHide();

    }
    datePanelHide() {
        if (this.state.enable8thDay) return;
        this.showHeaderDate = true;
        for (let item in this.state.quoteModel) {
            if (this.state.quoteModel[item].isOpen) this.showHeaderDate = false;
        }
    }

    removeLock = (e, key, index) => {
        this.setState({
            showUnlockModal: !this.state.showUnlockModal,
            lockIndex: index,
            lockKey: key
        })
    }
    unlockCol = () => {
        this.state.elements.map(
            (key, i) => {
                var arrEl = this.state.sel[key].map(function (o, i) {
                    return o;
                });
                arrEl[this.state.lockIndex].is_lock = !arrEl[this.state.lockIndex].is_lock
            })
        this.listActiveDays();
        this.forceUpdate();
        this.setState({
            showUnlockModal: !this.state.showUnlockModal,
            lockIndex: '',
            lockKey: ''
        })
    }

    isBaseLock = () => {
        let a = [];
        this.state.elements.map(
            (key, i) => {
                this.state.sel[key].map(function (o, i) {
                    if (o.is_base === true) {
                        a.push(i)
                    }
                });
            })
        a.map((val, ix) => {
            this.state.elements.map(
                (key, i) => {
                    this.state.sel[key][val].is_lock = false
                })
        })
        this.forceUpdate()

    }

    listActiveDays = () => {
        this.state.elements.map(
            (key, i) => {
                var sDay = this.state.sel[key].map(function (o, i) {
                    return o.is_lock;
                });
                var ks = []
                sDay.map(function (k, ix) {
                    if (k == false) {
                        ks.push(k)
                    }
                });
                this.unlockDays = ks;
                this.props.plotDays(this.unlockDays.length);
            })
    }

    getCounterOffer() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_offer/'
        var data = {
            "quote_id": localStorage.requestQuoteId,
            "weight_classes": this.state.elements
        }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.setState({
                    countData: response.data.data
                })
                let weightClass = Object.keys(this.state.countData)
                let defaultOffer = []
                weightClass.map((weight) => {
                    if (this.state.countData[weight].status === "Rejected") {
                        this.state.weiPriceTotal[weight] = 0
                        this.state.accStat[weight] = true
                    }
                    if (this.state.countData[weight].status === "default") {
                        defaultOffer.push("0")
                    }
                })
                console.log(defaultOffer.length, weightClass.length)
                if (defaultOffer.length === weightClass.length) {
                    this.setState({
                        allOfferDefault: true
                    })
                }
                else {
                    this.setState({
                        allOfferDefault: false
                    })
                }
                console.log(this.state.allOfferDefault)
                this.forceUpdate()
            })
            .catch((error) => {
                this.props.errData(error);
                console.log(error)
            })

    }

    clsModal = () => {
        this.setState({
            showUnlockModal: false,
            showAcceptAllModal: false,
            lockIndex: '',
            lockKey: '',
        })
    }





    render() {
        var daysOrder = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

        return (
            <Aux>
                <p></p>
                {
                    this.state.showUnlockModal && <Modal>
                        <div className={'mod_cls'} onClick={(event) => this.clsModal()}></div>
                        <div className={'pop_logo' + ' ' + 'alert'}></div>
                        <p>Are you sure about adding one additional day to your shipment? It might take some additional time for order confirmation.</p>
                        <div className={'modal_btn'} onClick={(event) => this.unlockCol()}>Continue</div>
                    </Modal>
                }
                {
                    this.state.showAcceptAllModal && <Modal>
                        <div className={'mod_cls'} onClick={(event) => this.clsModal()}></div>
                        <div className={'pop_logo' + ' ' + 'alert'}></div>
                        <p>Are you sure about accepting Seafood7 Price </p>
                        <div className={'modal_btn'} onClick={(event) => this.acceptAll()}>Continue</div>
                    </Modal>
                }
                {
                    this.state.showQuoteConfirmModal && <Modal>
                        <div className={'pop_logo' + ' ' + 'hook'}></div>
                        <p>Kindly note that we have received your request.<br /> You will receive a notification once the price quote is ready.</p>
                        <div className={'modal_btn'} onClick={(event) => this.confirmedQuote()}>Okay</div>
                    </Modal>
                }
                {
                    this.state.showAcceptRejectModal && <Modal>
                        {/* <div className={'pop_logo' + ' ' + 'hook'}></div> */}
                        {
                            this.acceptReject.action === "accept" ? <p>By clicking Accept, you are accepting the price quote of the weight class</p> : <p>
                                By clicking Remove, you are removing the weight class from the quote (Rejecting the price quote)
                            </p>
                        }

                        <div className={'modal_btn_wrp'}>
                            <div className={'modal_btn'} onClick={(event) => this.clsAcceptModal()}>Cancel</div>
                            <div className={'modal_btn'} onClick={(event) => this.acceptCancelConfirm()}>{this.acceptReject.action === "accept" ? "Accept" : "Remove"}</div>
                        </div>
                    </Modal>
                }

                <div className={'Base_block ' + (this.state.enable8thDay ? 'eigthDay' : '')}>
                    {
                        this.showHeaderDate && <div className={'Acd_hd accordion_header ' + (this.state.enable8thDay ? 'eigthHeader' : '')}>
                            {
                                !this.state.enable8thDay ? this.weekDet.map((val, i) => {
                                    return <div className={'Base_days' + ' ' + 'dayBf'}>

                                        <span className={'inData'}>

                                            {daysOrder[i + 1]} <Moment format="MMM DD">
                                                {val}
                                            </Moment>
                                        </span>
                                    </div>
                                }) : <div className={'Base_days' + ' ' + 'dayBf'}>

                                        <span className={'inData'}>

                                            {daysOrder[1]} <Moment format="MMM DD">
                                                {this.weekDet[0]}
                                            </Moment> - {daysOrder[7]} <Moment format="MMM DD">
                                                {this.weekDet[6]}
                                            </Moment>
                                        </span>
                                    </div>
                            }

                        </div>
                    }
                    {
                        this.state.elements.map(
                            (key, i) => {

                                return <div id={i} className={this.state.weiPriceTotal[key] == '0' || !this.state.quoteModel[key].hasVolume ? 'disOp' + ' ' + 'Base_ord_wrap' + ' ' + 'qte_bg' : 'Base_ord_wrap' + ' ' + 'qte_bg'}>
                                    <div className={'Base_ord_head Acd_hd ' + (this.state.quoteModel[key].hasVolume && !this.state.quoteModel[key].isOpen ? 'hasVolume' : '')}>
                                        <section>
                                            <span>HOG {key} kg</span>
                                            {this.state.weiPriceTotal[key] != '0' ? <span>Total {this.state.weiTotal[i]} {this.state.weiTotal[i] == 1 ? 'Box' : 'Boxes'}</span> : null}
                                        </section>
                                        {this.state.enable8thDay ? <section>
                                            {
                                                this.state.sel[key].map((days, index) => {
                                                    return <div className={'accordion_vol'}>
                                                        {/* <label>8th Day</label> */}
                                                        <input type="text" onBlur={(event) => this.onBlur(event, key, index)} Value={days.volume != '0' ? days.volume : ''} placeholder="0" />
                                                    </div>
                                                })
                                            }
                                        </section> : <section className={this.state.quoteModel[key].isOpen || !this.state.quoteModel[key].hasVolume ? 'hide' : ''}>
                                                {
                                                    this.state.sel[key].map((days, index) => {
                                                        return <div className={'accordion_vol'}>
                                                            {days.volume ? days.volume : ''}
                                                        </div>
                                                    })
                                                }
                                            </section>}
                                        {
                                            <section className={this.state.quoteModel[key].isOpen ? 'active' : null}>
                                                {
                                                    this.state.weiPriceTotal[key] != '0' && <div className={'btn_gp'}>
                                                        {/* <span>Total {this.state.weiTotal[i]} {this.state.weiTotal[i] == 1 ? 'Box' : 'Boxes'}</span> */}
                                                        {this.state.countData[key] ?.stage !== false && this.state.countData[key] ?.stage != 2 && this.state.countData[key] ?.stage != 1 && this.state.countData[key] ?.stage != 3 ? <span className={'strike_out'}> <label>{this.state.countData[key] ?.admin_offer ? this.state.countData[key] ?.admin_offer.toFixed(2) : "__"}</label>&nbsp;USD/kg</span> :
                                                            <span className={'strike_out'}>
                                                                <label className={this.state.countData[key] ?.next_move == 'customer' ? 'strike' : null}>{this.state.countData[key] ?.customer_counteroffer ? this.state.countData[key] ?.customer_counteroffer.toFixed(2) : "__"}</label>
                                                                <div className={'label_break'}></div>
                                                                <label className={this.state.countData[key] ?.next_move == 'admin' ? 'strike' : null}>{this.state.countData[key] ?.admin_offer ? this.state.countData[key] ?.admin_offer.toFixed(2) : "__"}</label>
                                                                &nbsp;USD/kg</span>}
                                                        {
                                                            (this.state.countData[key] ?.stage == "0" || this.state.countData[key] ?.stage == "3") && !this.state.accStat[key] ? <Aux>
                                                                {this.state.quoteData.in_negotiation ? <span className={'Accept_offer_button'} onClick={(event) => this.cancelOrAccept("accept", this.state.countData[key] ?.admin_offer, key)}>Accept</span> : null}

                                                                <div className={'counter_drop_btn'} onClick={(event) => this.counterOffer(event, this.state.countData[key] ?.admin_offer, key)}></div>

                                                                <div className={'counter_drop_btn' + ' ' + 'counter_cancel'} onClick={(event) => this.cancelOrAccept("reject", this.state.countData[key] ?.admin_offer, key)}></div>
                                                            </Aux> : <Aux>
                                                                    {
                                                                        this.state.countData[key] ?.stage == "4" && <span className={'accepted'}>Accepted</span>
                                                                    }
                                                                    {
                                                                        this.state.countData[key] ?.stage == "5" && <span className={'rejected'}>Removed</span>
                                                                    }
                                                                    {
                                                                        (this.state.countData[key] ?.stage == 1 || this.state.countData[key] ?.stage == 2) && <span className={'better'}>Better Price Asked</span>
                                                                    }



                                                                </Aux>
                                                        }
                                                        {!this.state.enable8thDay ? <div className={'Accord-tog-btn'} onClick={(event) => this.toggleAccordion(event, key, this.state.accStat[key])}></div> : null}
                                                    </div>
                                                }
                                                {
                                                    this.state.weiPriceTotal[key] == '0' && <Aux>
                                                        {
                                                            this.state.quoteModel[key].hasVolume && this.state.countData[key] != null ? <span className={'no_price'}>
                                                                {this.state.quoteData && this.state.quoteData.status == 2 ? '$_ _/Kg Pending' : '_ _'}
                                                            </span> : <span></span>
                                                        }
                                                        {
                                                            this.state.countData[key] ?.status == "Rejected" ? <Aux><span className={'strike_out'}>
                                                                <label className={'strike'}>{this.state.countData[key] ?.admin_price ?.price_quote ? this.state.countData[key] ?.admin_price ?.price_quote.toFixed(2) : "__"}</label>
                                                                <div className={'label_break'}></div>
                                                                <label className={'strike'}>{this.state.countData[key] ?.user_price ?.price_quote ? this.state.countData[key] ?.user_price ?.price_quote.toFixed(2) : "__"}</label>&nbsp;  USD/kg </span>
                                                                <span className={'rejected'}>Removed</span></Aux> : null
                                                        }
                                                        {!this.state.enable8thDay ? <div className={'Accord-tog-btn'} onClick={(event) => this.toggleAccordion(event, key, this.state.accStat[key])}></div> : null}
                                                    </Aux>
                                                }
                                            </section>
                                        }
                                    </div>
                                    {
                                        this.state.quoteModel[key].isOpen && <section className={'base_order_body'}>
                                            {
                                                this.state.sel[key].map((days, index) => {
                                                    return <div className={'Base_days ' + 'accHdLn ' + (days.value === 0 ? "hide" : "")}>

                                                        {
                                                            days.is_lock && <div className={'lock'} onClick={(event) => this.removeLock(event, key, index)}></div>
                                                        }
                                                        {
                                                            this.state.nwSel != 1 && <Aux>
                                                                <div className={'spn1'}>
                                                                    {daysOrder[days.dayIndex]} <Moment format="MMM DD">
                                                                        {this.weekDet[days.dayIndex]}
                                                                    </Moment>
                                                                    {/* {days.date} */}
                                                                </div>
                                                                {/* <span>{daysOrder[days.dayIndex]}</span> <br /> */}
                                                            </Aux>
                                                        }
                                                        {
                                                            this.state.nwSel == 1 && <Aux>
                                                                <div className={'spn1'}>
                                                                    {daysOrder[index + 1]} <Moment format="MMM DD">
                                                                        {this.weekDet[index]}
                                                                    </Moment>

                                                                </div>
                                                            </Aux>
                                                        }

                                                        <input type="text" onBlur={(event) => this.onBlur(event, key, index)} Value={days.volume != '0' ? days.volume : ''} placeholder="0" />
                                                    </div>
                                                })
                                            }
                                        </section>
                                    }
                                </div>
                            })
                    }
                </div>
                {
                    this.state.countModal && <CounterOffer counterDetails={this.counterDet} counterStatus={this.counterStatus}></CounterOffer>
                }
            </Aux >
        );
    }

};
export default BaseAccordion;