import React, { Component } from 'react';
import {Link, Route} from 'react-router-dom';
import Aux from '../../hoc/hoc';
import Sidebar from '../Sidebar/Sidebar';




class Flight extends Component {
    state = { 
    }
   
    render() {
        return (
            <Aux>
                <section className={'body_wrap'}>   
                    <Sidebar />   
                </section>
            </Aux>
        );
    }
};



export default Flight;