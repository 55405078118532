import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Ui/Button/Button';
import Input from '../../components/Ui/Input/Input';
import * as actions from '../../store/actions/index';
import axios from 'axios';
import className from './Auth.css'
import { Link } from 'react-router-dom';


class Auth extends Component {
    state = {
        error: false,
        emptyFeild: false,
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Mail Address',
                    label: 'Username',
                },
                value: '',
                validation: {
                    required: true,
                    // isEmail:true
                },
                valid: false,
                touched: false,
                onChange: false,
            },


            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password',
                    label: 'Password',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                onChange: false,
            }
        },
    }

    checkValidity(value, rules) {
        let isValid = false;
        if (rules.required) {
            isValid = value.trim() !== ''
        }
        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true,
                onChange: true,

            }
        };
        // updatedControls.valid = this.checkValidity(updatedControls.value, updatedControls.validation);
        // console.log(updatedControls)
        this.setState({ controls: updatedControls });
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value);
        if (!this.state.controls.email.valid || !this.state.controls.password.valid) {
            this.setState({ emptyFeild: true });
            return
        }
        else {
            const authData = {
                email: this.state.controls.email.value,
                password: this.state.controls.password.value
            }
            axios.post('/api/login/', authData)
                .then(response => {
                    // document.body.classList.add('loading-indicator');
                    console.log(response.data.data)
                    localStorage.setItem('token', response.data.data.token);
                    localStorage.setItem('userName', response.data.data.name);
                    localStorage.setItem('customer', response.data.data.customer);
                    localStorage.setItem('shortName', response.data.data.name);
                    localStorage.setItem('firstName', response.data.data.first_name);
                    localStorage.setItem('isAdmin', response.data.data.is_admin);
                    localStorage.setItem('isCustomerAdmin', response.data.data.is_customer_user_admin);
                    localStorage.setItem('isProspect', response.data.data.is_prospect_user);
                    localStorage.setItem('accessId', response.data.data.access_ids[0]);
                    localStorage.setItem('hasQuote', response.data.data.active_quote);
                    localStorage.setItem('isNote', response.data.data.show_customer_note);
                    // localStorage.setItem('customerLocation', response.data.data.customer_shipment_locations.length ? response.data.data.customer_shipment_locations[0] : '');

                    // if (localStorage.isAdmin === "true" || response.data.data.customer_shipment_locations.length > 1) {
                    if (localStorage.isAdmin === "true" || response.data.data.access_ids.length > 1) {
                        window.location = '/select-user';
                    } else {
                        window.location = '/home';
                    }
                })
                .catch(error => {
                    console.log("aaa", error);
                    // this.setState({error:true});
                    // dispatch(authFail(err));
                });
        }
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }
        const form = formElementsArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                touched={formElement.config.touched}
                onChange={(event) => this.inputChangedHandler(event, formElement.id)}
            />
        ));
        return (
            <div className={'loginWrap'}>
                {/* <div className={'bub_1'}>
                    <img src={require('../../assets/images/login_bubbles.png')} alt='log_bub' />
                </div>
                <div className={'bub_2'}>
                    <img src={require('../../assets/images/login_bubbles.png')} alt='log_bub' />
                </div>
                <div className={'bub_3'}>
                    <img src={require('../../assets/images/login_bubbles.png')} alt='log_bub' />
                </div>
                <div className={'l_top_im'}>
                    <img src={require('../../assets/images/t_l_im.png')} alt='log_bub' />
                </div>*/}
                <div className={'b_rt_im'}>
                    {/* <img src={require('../../assets/images/seafood7-element.svg')} alt='log_bub' /> */}
                </div>
                <div className={'loginOuter'}>
                    <section>
                        <div className={'logo_landing'}>
                        </div>
                        <p>Salmon across the seven seas</p>
                        <label>
                            Overseas salmon in 1-2-3
                        </label>
                        <div className={'downloadWrap'}>
                            {/* <p>Download Our App</p> */}
                            <div className={'down_btn_out'}>
                                <div className={'btn_app'}>
                                    <div className={'app_ico'}></div>
                                    Download from<br /> Android App Store
                                </div>
                                <div className={'btn_app'}>
                                    <div className={'app_ico'}></div>
                                    Download from<br /> App Store
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <form className={'loginFormWrap'} onSubmit={this.submitHandler}>
                            <p className={'loginFormHead'}>Login</p>
                            <p className={'loginFormSubHead'}>Please Login To Continue</p>
                            {form}
                            {this.state.emptyFeild &&
                                <p className={'log_err'}>Enter valid username and password</p>
                            }
                            <Button btnType="Success">Login</Button>
                            <Link to={'/forgot-password'}>
                                <p className={'fP'}>Forgot Password?</p>
                            </Link>

                        </form>
                    </section>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password))
    }
}

export default connect(null, mapDispatchToProps)(Auth);