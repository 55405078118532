import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Aux from '../../../hoc/hoc';
import BaseOrder from '../BaseOrder/BaseOrder';
import className from './DashboardOverview.css'

class DashboardOverview extends Component {
    constructor() {
        super()
        this.state = {
            quoteDetails: null,
            quotePresesnt: 0,
            indicativeStatus: "default",
            requestStatus: "default",
            confirmOrderPlaced: true
        }
        this.qtBtn = null;
        this.indicBtn = null;
        this.rqBtn = null;
        this.Qvo = true;
        this.pricAvailble = false;
        // this.quotePresesnt = 0
        // this.indicativeStatus = "default";
        // this.state.requestStatus = "default";
    }

    componentDidMount() {
        // this.getPriceStatus();
        this.getQuote();
    }

    getQuote() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/get-full/'
        let data = {};
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                console.log(response)
                this.pricAvailble = response.data.has_prices;
                if (response.data.quote.id) {
                    this.state.quotePresesnt = 1;
                }
                this.setState({
                    quoteDetails: response.data.quote,
                })
                console.log(this.state.quotePresesnt)
                this.processState(response.data);
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response) {
                    this.setState({
                        quoteDetails: error.response.data
                    })
                    if (error.response.data.quotee_placed) {
                        this.setState({
                            indicativeStatus: 'under_confirmation',
                            requestStatus: 'under_confirmation'
                        })
                    }
                }
            })
    }

    processState(data) {
        let indicative = 'default';
        let request = 'default';
        if (this.state.quotePresesnt != 0 && data.has_prices) {
            indicative = 'view';
        }
        if (data.quote.status == 2 && !data.has_prices) {
            indicative = 'pending';
        }

        if (this.state.quotePresesnt != 0 && data.has_volumes && data.quote.status == 1) {
            request = 'continue';
        }
        if (this.state.quotePresesnt != 0 && data.has_volumes && !data.has_prices && data.quote.status == 2) {
            console.log('quote_pending')
            request = 'quote_pending';
        }
        if (this.state.quotePresesnt != 0 && data.has_volumes && data.has_prices) {
            request = 'view';
        }
        if (data.quotee_placed) {
            request = 'under_confirmation';
        }

        this.setState({
            indicativeStatus: indicative,
            requestStatus: request
        })
        console.log(this.state.requestStatus);
    }

    getPriceStatus() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_price_indication_status/'
        let data = {};
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                let pAv = response.data.data.price_available;
                this.qVo = response.data.data.volume_available;
                this.pricAvailble = pAv;
                // this.indicativeStatus = response.data.data.indicative;
                this.state.requestStatus = response.data.data.request;
                localStorage.setItem("requestStatus", response.data.data.request)
                localStorage.setItem("indicStatus", response.data.data.indicative)
                localStorage.setItem('priceAvailable', this.pricAvailble)

                this.setState({
                    quoteDetails: response.data.data.quote_data
                })
                // this.quotePresesnt = Object.keys(this.state.quoteDetails).length
                // console.log(this.quotePresesnt)

                this.forceUpdate()
            })
            .catch((error) => {
                console.log(error)
            })
    }
    getPriceIndication() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }

        // let webApiUrl = '/customer/get_price_indication/'
        let webApiUrl = 'accumulator/api/quotee/'
        let data = {
            "product": 1
        };
        axios.post(webApiUrl, data,
            {
                headers: headers
            })
            .then(response => {
                console.log(response)
                console.log(response.data.data.id)
                if (response.data.data.id) {
                    this.setState({
                        indicativeStatus: 'pending'
                    })
                    this.forceUpdate();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    priceIndic() {
        if (this.state.indicativeStatus === 'view') {
            window.location = '/home/price';
            return;
        }
        this.getPriceIndication()
    }


    handleActBtn = (a) => {
        if (a != 0) {
            this.qtBtn = 6;
            localStorage.setItem("requestQuoteStatus", 'request')
        }
        this.forceUpdate();
        console.log(this.qtBtn)
    }
    updateOrderPlaced = (id) => {
        if(id !== 0){
            this.setState({
                confirmOrderPlaced: true
            })
        }
        else{
            this.setState({
                confirmOrderPlaced: false
            })
        }
       
    }
    render() {
        return (
            <Aux>
                <div className={'cont_wp'}>
                    <section className={'cmn_head'}>
                        <p>Seafood Dashboard</p>
                        {/* <label>Create New Orders & Modify base orders here</label> */}
                    </section>
                    <div className={'scrl_out'}>
                        <section className={'home_top_wrap'}>
                            <div className={'home_top_block'}>
                                <p>Manage <br /> Your Seafood</p>
                                <label>Manage your supply chain here</label>
                                <section className={'home_bt_out_wp'}>
                                    <div style={{display:"flex"}}>

                                   
                                    {
                                        // this.qtBtn != 6 && this.state.requestStatus != "under_confirmation" && this.state.requestStatus != "confirmed" && <> <Link to={'/home/request'}>
                                        //     {/* <div className={this.state.requestStatus == "continue" || this.state.requestStatus == "view" ? 'sp_btn' + ' ' + 'home_btn' : 'home_btn'}> */}
                                        //     <div className={'sp_btn' + ' ' + 'home_btn'}>   
                                        //         {/* {
                                        //             this.state.requestStatus == "default" && <span>Build Request</span>
                                        //         }
                                        //         {
                                        //             this.state.requestStatus == "continue" && <span>Continue Build Request</span>
                                        //         }
                                        //         {
                                        //             this.state.requestStatus == "quote_pending" && <span>Quote Pending</span>
                                        //         }
                                        //         {
                                        //             this.state.requestStatus == "view" && <span>View Quote</span>
                                        //         } */}
                                        //         Buy
                                        //     </div>

                                        // </Link>
                                        <>
                                            {/* <Link to="/home/request" >   */}
                                            <div className={`${this.state.quoteDetails ?.status == 3 || this.state.confirmOrderPlaced ? 'disabled_btn-container' : ''}`}>
                                                <div className={`disabled_btn`} style = {{ cursor: this.state.quoteDetails ?.status == 3 || this.state.confirmOrderPlaced ? 'not-allowed' : 'pointer'}} onClick={()=>this.state.quoteDetails ?.status == 3 || this.state.confirmOrderPlaced? null : window.location = 'home/request'}>
                                                    Buy
                                                </div>
                                            </div>
                                            {/* </Link> */}
                                            <div className={"disabled_btn-container"}>
                                                <div className={'disabled_btn not-allowed'}>
                                                        <div class="flight_ico"></div>
                                                </div>
                                            </div>
                                            <div className={'disabled_btn'}>
                                                Sell
                                            </div>
                                        </>
                                    }
                                    </div>
                                    {/* {
                                        this.qtBtn == 6 && this.state.requestStatus != "under_confirmation" && this.state.requestStatus != "confirmed" && <Link to={'/home/request'}>
                                            <div className={this.qtBtn == 6 ? 'revReq' + ' ' + 'home_btn' : 'home_btn' + ' ' + 'sp_btn'}>
                                                {
                                                    this.state.requestStatus == "continue" && <span>Continue Quote</span>
                                                }
                                                {
                                                    this.state.requestStatus == "quote_pending" && <span>Quote Pending</span>
                                                }
                                                {
                                                    this.state.requestStatus == "default" && <span>Request A Quote</span>
                                                }
                                                {
                                                    this.state.requestStatus == "view" && <span>View Quote</span>
                                                }
                                            </div>
                                        </Link>
                                    } */}

                                    {
                                        this.state.requestStatus == "under_confirmation" &&
                                        <div className={'home_btn'}>
                                            Order Under Confirmation
                                                </div>

                                    }
                                    {
                                        this.state.requestStatus == "confirmed" && <Link to={'/home/checkout'}>
                                            <div className={'home_btn'}>
                                                View Order
                                                </div>
                                        </Link>
                                    }
                                    {
                                        this.state.indicativeStatus == "default" && <div onClick={() => this.priceIndic()} className={this.state.requestStatus == "continue" || this.qtBtn == 6 ? 'button_circle' : 'button_circle' + ' ' + 'sp_btn'}>
                                            Ask Price
                                            </div>
                                    }
                                    {
                                        this.state.indicativeStatus == "view" && <div onClick={() => this.priceIndic()} className={this.state.requestStatus == "continue" || this.state.requestStatus == "view" || this.state.requestStatus == "under_confirmation" || this.qtBtn == 6 ? 'button_circle' : 'button_circle' + ' ' + 'sp_btn'}>
                                            View Price
                                            </div>
                                    }
                                    {
                                        this.state.indicativeStatus == "quote_pending" && <div onClick={() => this.priceIndic()} className={'button_circle'}>
                                            Quote Pending Price
                                            </div>
                                    }
                                    {
                                        this.state.indicativeStatus == "pending" && <div className={'button_circle'}>
                                            Price Pending
                                            </div>
                                    } 



                                </section>
                            </div>
                            <div className='home_top_block'>
                                {/* <div className={'home_blk_price_wrap'}>
                                        <div className={'home_blk_price'}>
                                            <label>Total Buy</label>
                                            <p>---</p>
                                        </div>
                                        <div className={'home_blk_price'}>
                                            <label>Total Spend</label>
                                            <p>---</p>
                                        </div>
                                        <div className={'home_blk_price'}>
                                            <label>Next Order</label>
                                            <p>---</p>
                                        </div>
                                    </div> */}

                                <div className={'home_blk_stat_wrap'}>
                                    <div className={'home_blk_stat'}>
                                        {/* <div className={'count_tag'}>Quote Id()</div> */}
                                        {
                                            <p>Active Discussions</p>
                                        }
                                        {
                                            this.state.quoteDetails ?.id && <label>Quote : {this.state.quoteDetails ?.id}</label>
                                            }
                                        <p></p>
                                        {
                                            this.state.quoteDetails ?.id && <label>Shipment Week : {this.state.quoteDetails ?.shipment_week}</label>
                                            }
                                        {
                                            this.state.quotePresesnt == 0 ? <label>No Active Discussions</label> : <label></label>
                                        }

                                        <div className={'home_conf_outer'}>
                                            {/* {
                                                    this.indicativeStatus=="quote_pending"&&<div className={'home_conf_status'}>
                                                        <span>Quote Pending From Admin</span>
                                                    </div>
                                                } */}
                                            {
                                                this.state.indicativeStatus == "view" && <div className={'home_conf_status'}>
                                                    <span>Price Available</span>
                                                </div>
                                            }
                                            {
                                                this.state.indicativeStatus == "pending" && <div className={'home_conf_status'}>
                                                    <span>Price Pending From Admin</span>
                                                </div>
                                            }
                                            {
                                                this.state.requestStatus == "continue" && <div className={'home_conf_status' + ' ' + 'req_status'}>
                                                    {
                                                        this.qtBtn == 6 ? <span>Building Quote</span> : <span>Building Request</span>
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.requestStatus == "under_confirmation" && <div className={'home_conf_status' + ' ' + 'req_status'}>
                                                    <span>Pending Order Confirmation</span>
                                                </div>
                                            }

                                            {
                                                this.state.indicativeStatus == "quote_pending" && <div className={'home_conf_status' + ' ' + 'req_status'}>
                                                    {
                                                        this.qtBtn == 6 && <span>Quote Pending From Admin</span>
                                                    }
                                                </div>
                                            }
                                        </div>



                                    </div>
                                    <div className={'home_blk_stat'}>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className={'home_top_wrap'}>
                            <BaseOrder actBtn={this.handleActBtn} updateOrderPlaced={this.updateOrderPlaced}/>
                        </section>
                    </div>
                </div>
            </Aux>
        );
    }
};

export default DashboardOverview;