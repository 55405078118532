import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import { Link } from 'react-router-dom';
import axios from 'axios';

class adminLanding extends Component {
    constructor() {
        super();
        this.state = {
            options: [],
        }
    }
    componentDidMount() {
        this.getUserList();
    }
    getUserList() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/api/customer-shipment-location/?access_id=' + localStorage.accessId;
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                // console.log(response.data.results)
                localStorage.setItem('customerList', JSON.stringify(response.data.results));
                this.setState({
                    options: response.data.results
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    handleChange = e => {
        // localStorage.setItem('customerLocation', e.target.value);
        localStorage.setItem('accessId', e.target.value);


        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/api/login-extended/?access_id=' + localStorage.accessId;
        axios.post(webApiUrl, {}, {
            headers: headers
        })
            .then(response => {
                console.log(response.data.data)
                localStorage.setItem('onBase', response.data.data.base_order);
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('accessId', response.data.data.active_access_id);
                window.location = '/home/';
            })
            .catch((error) => {
                console.log(error)
            })
    }


    render() {


        return (
            <Aux>
                <div className={'loginWrap'}>
                    {/* <div className={'bub_1'}>
                        <img src={require('../../../assets/images/login_bubbles.png')} alt='log_bub' />
                    </div>
                    <div className={'bub_2'}>
                        <img src={require('../../../assets/images/login_bubbles.png')} alt='log_bub' />
                    </div>
                    <div className={'bub_3'}>
                        <img src={require('../../../assets/images/login_bubbles.png')} alt='log_bub' />
                    </div>
                    <div className={'l_top_im'}>
                        <img src={require('../../../assets/images/t_l_im.png')} alt='log_bub' />
                    </div>
                    <div className={'b_rt_im'}>
                        <img src={require('../../../assets/images/b_r_im.png')} alt='log_bub' />
                    </div> */}
                    <div className={'loginOuter'}>
                        <section>
                            <div className={'logo_landing'}>
                            </div>
                            <p>Salmon across the seven seas</p>
                            <label>
                                Overseas salmon in 1-2-3
                        </label>
                        </section>
                        <section>
                            <p className={'loginFormHead'}>Welcome ,<br />
                                {localStorage.userName}
                            </p>
                            <p className={'loginFormSubHead'}>Please select a user to continue</p>

                            <select className={'selectUserByAdmin'} name='selectedOption' onChange={this.handleChange}
                            >
                                <option value=''>Select Location</option>
                                {
                                    this.state.options.map((i) => {
                                        return <option value={i.id}>
                                            {i.name}
                                        </option>
                                    })
                                }
                            </select>
                            <Link to={'/'}>
                                <p className={'back_link'}>Back To Login</p>
                            </Link>
                        </section>
                    </div>
                </div>
            </Aux>
        );
    };

};

export default adminLanding;