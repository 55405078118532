import React, { Component } from 'react';
import Aux from '../../../hoc/hoc';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import className from './BaseOrder.css'

class BaseOrder extends Component {
    constructor() {
        super();
        this.state = {
            elements: [],
            sel: null,
            totalOrder: 0,
            custDetails: null,
            selectedQuote: 0,
            addrDrop: false,
            activeTab: 'base',
            accStat: [],
            lastElem: [],
            lastSel: null,
            lastTotal: 0,
            orderDet: {},
            quoteModel: {},
            activeOrderMatrix: [],
            activeTotal: 0,
            activeTotalPrice: 0,
            activeOrderPrice: {},
            pdfData: [],
            orderType: 'active',
            noteMatrix: [],
            noteTotalOrder: 0,
            showNotes: false,
            forecastTotalOrder: 0,
            forecastElements: [],
            forecastSel: null,
            foreCastQuoteModel: {}
        }
        this.weekDet = [];
        this.baseDetails = [];
        this.shipment_address = '';
    }

    componentDidMount() {
        this.getActiveOrder();
        // this.getPendingOrder();
        this.getDate();
        this.getForecast();
        this.getBaseOrder();
        this.getNotes();  
        localStorage.setItem('baseStatus', 0);
        localStorage.removeItem('requestData');
        localStorage.removeItem('requestQuoteId');
        localStorage.removeItem('locationId');
        this.setState({
            showNotes: localStorage.getItem('isNote')
        })

    }

    getForecast() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/forecast-order/'
        axios.get(webApiUrl, {
            headers: headers
        })
        .then(response => {
                let model = {};
                for (let item in response.data.result) {
                    let sum = 0;
                    response.data.result[item].forEach(element => {
                        sum += element.volume;
                    });
                    model[item] = {
                        hasVolume: sum != 0,
                    }
                }
                this.setState({
                    forecastElements: Object.keys(response.data.result),
                    forecastSel: response.data.result,
                    foreCastQuoteModel: model,
                    forecastTotalOrder: response.data.total_weight,
                })
               
                this.state.forecastElements.map(
                    (key, i) => {
                        this.state.forecastSel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                    })
                this.sumAccord();
                // localStorage.setItem('requestData', JSON.stringify(response.data));
                this.forceUpdate()
        
        })
        .catch((error) => {
            console.log(error)
        })
    }

    getBaseOrder() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/base-order/?product_id=1'
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                let model = {};
                for (let item in response.data.result) {
                    let sum = 0;
                    response.data.result[item].forEach(element => {
                        sum += element.volume;
                    });
                    model[item] = {
                        hasVolume: sum != 0,
                    }
                }
                this.setState({
                    elements: Object.keys(response.data.result),
                    sel: response.data.result,
                    totalOrder: response.data.total_weight ? response.data.total_weight : 0,
                    quoteModel: model
                })
                this.props.actBtn(this.state.totalOrder);

                this.state.elements.map(
                    (key, i) => {
                        this.state.sel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                    })
                this.sumAccord();
                localStorage.setItem('requestData', JSON.stringify(response.data));
                if (this.state.elements.length != 0) {
                    localStorage.setItem("onBase", true);
                } else {
                    localStorage.setItem("onBase", false);
                }
                this.forceUpdate()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getNotes() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/customer-note/'
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                let order = [];
                for (let item in response.data.result) {
                    let temp = {};
                    temp['key'] = item;
                    temp['list'] = response.data.result[item].sort((a, b) => (a.weight_class > b.weight_class) ? 1 : ((b.weight_class > a.weight_class) ? -1 : 0));
                    order.push(temp);
                }
                this.setState({
                    noteMatrix: order,
                    noteTotalOrder: response.data.total_weight ? response.data.total_weight : 0,
                    noteData: response.data.customer_data
                })
                localStorage.setItem("noteId", response.data.customer_data.id)
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    resetNote() {
        let matrix = {}
        for (let item of this.state.noteMatrix) {
            matrix[item.key] = item.list.map(item => { item.volume = 0; return item });
        }
        console.log(matrix);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/customer-note/?customer_note_id=' + this.state.noteData.id
        var data = { "data": matrix }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                // ToastsStore.success("Note updated");
                // window.location.href = '/home';
                this.state.noteData.is_announced = true;
                this.updateAnnounce();
                this.getNotes();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    updateAnnounce() {
        this.state.noteData.is_announced = !this.state.noteData.is_announced;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/customer-note/' + this.state.noteData.id + '/announce-note/';
        let data = {
            "is_announce": this.state.noteData.is_announced
        }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getActiveOrder() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/order-placed/?request_type=active'
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                if(response.data){
                    this.props.updateOrderPlaced(response.data.id)
                }
                if (response.data.limited_order_matrix) {
                    let order = [];
                    for (let item in response.data.limited_order_matrix) {
                        let temp = {};
                        temp['dayIndex'] = response.data.limited_order_matrix[item][0].date;
                        temp['list'] = response.data.limited_order_matrix[item].sort((a, b) => (a.weight_class > b.weight_class) ? 1 : ((b.weight_class > a.weight_class) ? -1 : 0));
                        order.push(temp);
                    }
                    this.setState({
                        activeOrderMatrix: order,
                        activeTotal: response.data.total_volume,
                        activeTotalPrice: response.data.total_volume_c,
                        activeTab: 'active',
                        orderType: 'active',
                        pdfData: response.data.pdf_data,
                        activeOrderPrice: response.data.order_price_offer
                    })
                    this.forceUpdate()
                } else {
                    this.getPendingOrder();
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }
    getPendingOrder() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/0/view-order/?is_pending=1';
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                if (response.data.check_out_shipment_matrix) {
                    let order = [];
                    for (let item in response.data.check_out_shipment_matrix) {
                        let temp = {};
                        temp['dayIndex'] = response.data.check_out_shipment_matrix[item][0].date;
                        temp['list'] = response.data.check_out_shipment_matrix[item].sort((a, b) => (a.weight_class > b.weight_class) ? 1 : ((b.weight_class > a.weight_class) ? -1 : 0));
                        order.push(temp);
                    }
                    this.setState({
                        activeOrderMatrix: order,
                        activeTotal: response.data.total_volume,
                        activeTotalPrice: response.data.quotee_total,
                        activeTab: 'active',
                        orderType: 'pending',
                        activeOrderPrice: response.data.active_admin_offer_full
                    })
                    this.forceUpdate()
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getDate() {
        var nextMonday = new Date();
        nextMonday.setDate(nextMonday.getDate() + (1 + 7 - nextMonday.getDay()) % 7);
        var nextWeekArrays = [nextMonday];
        for (let i = 1; i < 7; i++) {
            nextWeekArrays.push(new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i)))
        }
        this.weekDet = nextWeekArrays;
    }

    getLastOrder(i) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_last_order/'
        var data = { "customer_shipment_location": i }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.setState({
                    lastElem: Object.keys(response.data.order_details),
                    lastSel: response.data.order_details,
                    lastTotal: response.data,
                    orderDet: response.data.order,
                })
                this.state.lastElem.map(
                    (key, i) => {
                        this.state.lastSel[key].sort(function (x, y) {
                            return x['dayIndex'] - y['dayIndex'];
                        });
                    })
                // this.forceUpdate();
                // console.log("lasr order",a)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    toggDrop() {
        this.setState({
            addrDrop: !this.state.addrDrop
        });
    }
    changeShipment = (event, a, i) => {
        this.state.selectedQuote = i;
        this.getBaseOrder(a);
        this.forceUpdate();

    }
    toggBase = (tab) => {
        this.setState({
            activeTab: tab
        })
    }
    sumAccord = () => {
        this.state.elements.map(
            (key, i) => {
                var arrEl = this.state.sel[key].map(function (o, i) {
                    return o.value;
                });
                var sum = arrEl.reduce(function (a, b) {
                    return a + b;
                }, 0);
                var accrStat = (sum === 0) ? true : false
                this.state.accStat[i] = accrStat;
            })
        // var a =this.state.lastElem.sort((x,y) => {
        //     return x['dayIndex']-y['dayIndex'];
        // })
    }
    render() {
        var daysOrder = ["", "Mon", "Tue", "Wed", "Thu", "Fr", "Sat", "Sun"]
        return (
            <Aux>
                <section className={'base_add_wrp'}>
                    <div className={'base_tog_btn'}>
                        <div className={this.state.activeTab == 'active' ? "actBtnB " : null} onClick={() => this.toggBase('active')}>{this.state.orderType === 'active' ? 'Active' : 'Pending'} Order</div>
                        <div className={this.state.activeTab == 'base' ? "actBtnB " : null} onClick={() => this.toggBase('base')}>Base Order</div>
                        <div className={this.state.activeTab == 'last' ? "actBtnB " : null} onClick={() => this.toggBase('last')}>Last Order</div>
                        <div className={this.state.activeTab == 'forecast' ? "actBtnB " : null} onClick={() => this.toggBase('forecast')}>Forecast Order</div>
                        {this.state.showNotes == 'true' && <div className={this.state.activeTab == 'note' ? "actBtnB " : null} onClick={() => this.toggBase('note')}>Notes</div>}
                    </div>
                    {
                        this.state.activeTab == 'active' && <section className={'Base_wrap'}>
                            {
                                this.state.activeTotal != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>{this.state.orderType === 'active' ? 'Active' : 'Pending'} Order</p>
                                        <div className={'Base_total_block'}>
                                            <span>{this.state.activeTotal} {this.state.activeTotal == 1 ? 'Box' : 'Boxes'}</span> <br />
                                            <label>Total Quantity</label>
                                        </div>
                                        <div className={'Base_total_block'}>
                                            <span>{this.state.activeTotalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}</span> <br />
                                            <label>Order Total USD</label>
                                        </div>
                                        <div className={'Base_addr_block'}>
                                            <div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
                                                {this.shipment_address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'Base_block'}>
                                        <div className={'Acd_hd base_header'}>
                                            {
                                                this.state.activeOrderMatrix[0] && this.state.activeOrderMatrix[0].list.map((key, i) => {
                                                    return <div className={'Base_days' + ' ' + 'dayBf'}>

                                                        <span className={'inData'}>

                                                            {key.weight_class} kg
                                                        </span>
                                                    </div>
                                                })
                                            }

                                        </div>

                                        {
                                            this.state.activeOrderMatrix.map(
                                                (key, i) => {

                                                    return !this.state.accStat[i] && <div className={'base_ord_table Base_ord_head '}>
                                                        {this.state.pdfData[i] && this.state.pdfData[i].pdf ? <a className={'doc_icon active'} href={axios.defaults.baseURL + this.state.pdfData[i].pdf} target="_blank"></a> : <div className={'doc_icon'}></div>}
                                                        <p className={'Base_ord_weight'}>
                                                            {key.dayIndex}</p>
                                                        <section>
                                                            {
                                                                key.list.map((days, index) => {
                                                                    return <div key={index} className={'Base_days'}>
                                                                        <span>{days.volume ? days.volume : '-'}</span>
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }
                                        <div className={'base_ord_table Base_ord_head '}>
                                            <div className={'doc_icon hide'}></div>
                                            <p className={'Base_ord_weight'}>
                                                Price/Kg</p>
                                            <section>
                                                {
                                                    this.state.activeOrderMatrix[0] && this.state.activeOrderMatrix[0].list.map((key, index) => {
                                                        return <div key={index} className={'Base_days'}>
                                                            <span>{this.state.activeOrderPrice[key.weight_class] ?.toFixed(2)}</span>
                                                        </div>
                                                    })
                                                }
                                            </section>
                                        </div>

                                    </div>
                                </Aux>
                            }
                        </section>
                    }
                    {
                        this.state.activeTab == 'base' && <section className={'Base_wrap'}>
                            {
                                this.state.totalOrder != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>Base Order</p>
                                        <div className={'Base_total_block'}>
                                            <span>{this.state.totalOrder} {this.state.totalOrder == 1 ? 'Box' : 'Boxes'}</span> <br />
                                            <label>Total Quantity</label>
                                        </div>
                                        <div className={'Base_addr_block'}>
                                            <div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
                                                {this.shipment_address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'Base_block'}>
                                        <div className={'Acd_hd base_header'}>
                                            {
                                                this.weekDet.map((val, i) => {
                                                    return <div className={'Base_days' + ' ' + 'dayBf'}>

                                                        <span className={'inData'}>

                                                            {daysOrder[i + 1]}
                                                        </span>
                                                    </div>
                                                })
                                            }

                                        </div>

                                        {
                                            this.state.elements.map(
                                                (key, i) => {

                                                    return !this.state.accStat[i] && <div className={'base_ord_table Base_ord_head ' + (this.state.quoteModel[key].hasVolume ? 'hasVolume' : '')}>
                                                        <p className={'Base_ord_weight'}>
                                                            HOG {key} kg
                                                </p>
                                                        <section>
                                                            {
                                                                this.state.sel[key].map((days, index) => {
                                                                    return <div key={index} className={'Base_days'}>
                                                                        {/* <div className={'hide_ovl'}></div> */}
                                                                        {/* <span>{daysOrder[days.dayIndex]}</span> <br /> */}
                                                                        <span>{days.volume ? days.volume : ''}</span>
                                                                        {/* <div className={'Base_tool_tip'}>
                                                                            <div>{days.date}</div>
                                                                            <div>{days.volume} Box</div>
                                                                        </div> */}
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }

                                    </div>
                                </Aux>
                            }
                            {
                                this.state.totalOrder == 0 && <Link className={'ed_out'} to={'/home/create-baseorder'}><div>
                                    Create Base Order
                                </div>
                                </Link>
                            }
                        </section>
                    }
                    {
                        this.state.activeTab == 'last' && <section className={'Base_wrap'}>
                            {
                                this.state.lastTotal.total_weight != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>Last Order</p>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.lastTotal.total_weight} {this.state.lastTotal.total_weight == 1 ? 'Box' : 'Boxes'}</span> <br />
                                                <label>Total Quantity</label>
                                            </div>
                                        </section>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.lastTotal.total_price} USD</span> <br />
                                                <label>Total Price</label>
                                            </div>
                                        </section>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.orderDet.placed_week}</span> <br />
                                                <label>Order Week</label>
                                            </div>
                                        </section>
                                        <section className={'blk_sep'}>
                                            <div className={'Base_total_block'}>
                                                <span>{this.state.orderDet.shipment_week}</span> <br />
                                                <label>Shipment Week</label>
                                            </div>
                                        </section>
                                    </div>

                                    <div className={'Base_block'}>
                                        {
                                            this.state.lastElem.map(
                                                (key, i) => {

                                                    return <div className={'Base_ord_wrap'}>
                                                        <p className={'Base_ord_head'}>
                                                            HOG {key} kg
                                                    </p>
                                                        <section>
                                                            {
                                                                this.state.lastSel[key].map((days, index) => {
                                                                    return <div key={index} className={'Base_days ' + (days.value === 0 ? "hide" : "")}>
                                                                        <div className={'hide_ovl'}></div>
                                                                        <span>{daysOrder[days.dayIndex]}</span> <br />
                                                                        <span>{days.value}</span>
                                                                        <div className={'Base_tool_tip'}>
                                                                            <div>{days.date}</div>
                                                                            {/* <div>{daysOrder[days.dayIndex]}</div> */}
                                                                            <div>{days.value} Box</div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }

                                    </div>
                                </Aux>
                            }
                            {
                                this.state.lastTotal.total_weight == 0 && <p className={'Nop'}>You don’t have previous orders. Please create an order from above.</p>
                            }
                        </section>
                    }
                    {
                        this.state.activeTab == 'note' && <section className={'Base_wrap'}>
                            {
                                this.state.noteTotalOrder != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>Notes</p>
                                        <div className={'Base_total_block'}>
                                            <span>{this.state.noteTotalOrder} {this.state.noteTotalOrder == 1 ? 'Box' : 'Boxes'}</span> <br />
                                            <label>Total Quantity</label>
                                        </div>
                                        <div className={'announce_wrap'}>
                                            <p>Announce</p>
                                            <div className={'toggle_wrap'}>
                                                <input type="checkbox" id="announce" checked={this.state.noteData.is_announced} />
                                                <label for="announce" onClick={() => this.updateAnnounce()}></label>
                                            </div>
                                        </div>
                                        <Link to={'/home/create-note'}>
                                            <div className="sp_btn home_btn note_btn">Edit Note</div>
                                        </Link>
                                        <div className="home_btn note_btn" onClick={() => this.resetNote()}>Reset Note</div>
                                        <div className={'Base_addr_block'}>
                                            <div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
                                                {this.shipment_address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'Base_block'}>
                                        <div className={'Acd_hd base_header note_header'}>
                                            {
                                                this.state.noteMatrix[0] && this.state.noteMatrix[0].list.map((key, i) => {
                                                    return <div className={'Base_days' + ' ' + 'dayBf'}>

                                                        <span className={'inData'}>

                                                            {daysOrder[key.dayIndex]}
                                                        </span>
                                                    </div>
                                                })
                                            }

                                        </div>

                                        {
                                            this.state.noteMatrix.map(
                                                (key, i) => {

                                                    return !this.state.accStat[i] && <div className={'base_ord_table Base_ord_head '}>
                                                        <p className={'Base_ord_weight'}>
                                                            {key.key} Kg</p>
                                                        <section>
                                                            {
                                                                key.list.map((days, index) => {
                                                                    return <div key={index} className={'Base_days'}>
                                                                        <span>{days.volume ? days.volume : ' '}</span>
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }

                                    </div>
                                </Aux>
                            }
                            {
                                this.state.noteTotalOrder == 0 && <Link className={'ed_out'} to={'/home/create-note'}><div>
                                    Create Note
                                </div>
                                </Link>
                            }
                        </section>
                    }
                    {this.state.activeTab == 'forecast' && <section className={'Base_wrap'}>
                        
                    {
                        this.state.forecastTotalOrder != 0 && <Aux>
                                    <div className={'Base_block'}>
                                        <p className={'Base_head'}>Forecast Order</p>
                                        <div className={'Base_total_block'}>
                                            <span>{this.state.forecastTotalOrder} {this.state.forecastTotalOrder == 1 ? 'Box' : 'Boxes'}</span> <br />
                                            <label>Total Quantity</label>
                                        </div>
                                        {/* <div className={'Base_addr_block'}>
                                            <div className={'ship_addr_wrp'} onClick={() => this.toggDrop()}>
                                                {this.shipment_address}
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className={'Base_block'}>
                                        <div className={'Acd_hd base_header'}>
                                            {
                                                this.weekDet.map((val, i) => {
                                                    return <div className={'Base_days' + ' ' + 'dayBf'}>

                                                        <span className={'inData'}>

                                                            {daysOrder[i + 1]}
                                                        </span>
                                                    </div>
                                                })
                                            }

                                        </div>
                                        {
                                            this.state.forecastElements.map(
                                                (key, i) => {

                                                    return !this.state.accStat[i] && <div className={'base_ord_table Base_ord_head ' + (this.state.foreCastQuoteModel[key].hasVolume ? 'hasVolume' : '')}>
                                                        <p className={'Base_ord_weight'}>
                                                            HOG {key} kg
                                                </p>
                                                        <section>
                                                            {
                                                                this.state.forecastSel[key].map((days, index) => {
                                                                    return <div key={index} className={'Base_days'}>
                                                                        {/* <div className={'hide_ovl'}></div> */}
                                                                        {/* <span>{daysOrder[days.dayIndex]}</span> <br /> */}
                                                                        <span>{days.volume ? days.volume : ''}</span>
                                                                        {/* <div className={'Base_tool_tip'}>
                                                                            <div>{days.date}</div>
                                                                            <div>{days.volume} Box</div>
                                                                        </div> */}
                                                                    </div>
                                                                })
                                                            }
                                                        </section>
                                                    </div>
                                                })
                                        }

                                    </div>
                                </Aux>
                            }
                            {
                                this.state.forecastTotalOrder == 0 && <Link className={'ed_out'} to={'/home/create-forecast'}><div>
                                    Create Forecast
                                </div>
                                </Link>
                            }
                        </section>
                    }
                </section>
            </Aux>
        );
    }
};

export default BaseOrder;