import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar';
import Aux from '../../../hoc/hoc';
import Modal from '../../Ui/Modal/Modal';
import Moment from 'react-moment';
import className from './OrderSummary.css'

class OrderSummary extends Component {
    constructor() {
        super();
        this.state = {
            daysList: [],
            summaryData: null,
            quoteSummary: null,
            perKgPrice: null,
            showQuoteConfirmModal: false,
            onDiscussionPrices: [],
            shipmentMatrix: []
        }
        this.weightClass = [];
        this.totalValue = [];
        // this.totalPrice = [];

        this.weekDet = [];
    }
    componentDidMount() {
        this.getDate()
        // this.getSummary()
        // this.getQuote();
        this.getOrder();
    }
    getDate() {
        var nextMonday = new Date();
        nextMonday.setDate(nextMonday.getDate() + (1 + 7 - nextMonday.getDay()) % 7);
        var nextWeekArrays = [nextMonday];
        for (let i = 1; i < 7; i++) {
            nextWeekArrays.push(new Date(new Date(nextMonday).setDate(nextMonday.getDate() + i)))
        }
        this.weekDet = nextWeekArrays;
    }

    getQuote() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/get-full/';
        let data = {};
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                console.log(response.data);
                let weight = [];
                for (let item in response.data.active_admin_offer_limited) {
                    weight.push(item);
                }
                let shipment = [];
                let columTotal = {};
                for (let item in response.data.limited_shipment_matrix) {
                    shipment.push({
                        date: item,
                        list: response.data.limited_shipment_matrix[item]
                    });
                    response.data.limited_shipment_matrix[item].map((weight, index) => {
                        columTotal[weight.weight_class] = columTotal[weight.weight_class] ? columTotal[weight.weight_class] + weight.volume : weight.volume;
                    })
                }
                console.log(weight)
                response.data.quote['quotee_total'] = response.data.quotee_total;
                response.data.quote['total_volume'] = response.data.total_volume;
                response.data.quote['total_weight'] = response.data.total_weight;
                this.setState({
                    // daysList: Object.keys(response.data.quote_data),
                    summaryData: response.data.quote_data,
                    quoteSummary: response.data.quote,
                    perKgPrice: response.data.active_admin_offer_full,
                    // onDiscussionPrices: Object.keys(response.data.on_discussion_prices),
                    // weightClass: weight,
                    shipmentMatrix: shipment
                })
                this.weightClass = weight;
                this.totalValue = columTotal;

                this.forceUpdate();

            })
            .catch((error) => {
                console.log(error)
            })
    }

    getOrder() {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/' + localStorage.requestQuoteId + '/view-order/';
        var apiData = {}
        axios.get(webApiUrl, {
            headers: headers
        })
            .then(response => {
                console.log(response)
                // window.location.href = '/home/checkout';
                let quote = {
                    quote_week: response.data.quote_week,
                    shipment_week: response.data.shipment_week,
                    id: response.data.quotee_id,
                    quotee_total: response.data.quotee_total,
                    total_volume: response.data.total_volume,
                    total_weight: response.data.total_weight
                };
                let weight = [];
                for (let item in response.data.matched_weight_class) {
                    weight.push(response.data.matched_weight_class[item]);
                }
                let shipment = [];
                let columTotal = {};
                for (let item in response.data.check_out_shipment_matrix) {
                    shipment.push({
                        date: item,
                        list: response.data.check_out_shipment_matrix[item]
                    });
                    response.data.check_out_shipment_matrix[item].map((weight, index) => {
                        columTotal[weight.weight_class] = columTotal[weight.weight_class] ? columTotal[weight.weight_class] + weight.volume : weight.volume;
                    })
                }

                this.setState({
                    quoteSummary: quote,
                    perKgPrice: response.data.active_admin_offer_full,
                    shipmentMatrix: shipment
                })
                this.weightClass = weight;
                this.totalValue = columTotal;
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getSummary() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/customer/get_quote_for_checkout/'
        let data = {};
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.setState({
                    daysList: Object.keys(response.data.data.quote_data),
                    summaryData: response.data.data.quote_data,
                    quoteSummary: response.data.data.quote,
                    perKgPrice: response.data.data.prices,
                    onDiscussionPrices: Object.keys(response.data.data.on_discussion_prices),
                })
                console.log(this.state.summaryData);
                let availableWeight = [];
                this.state.daysList.map((days, index) => {
                    availableWeight.push(...Object.keys(this.state.summaryData[days]))
                })
                this.weightClass = Array.from(new Set(availableWeight));

                console.log("w_class", this.weightClass)
                let columTotal = [];
                let columnPriceTotal = [];
                this.state.daysList.map((days, index) => {
                    this.weightClass.map((wClass, i) => {
                        if (!columTotal.hasOwnProperty(wClass)) {
                            columTotal[wClass] = this.state.summaryData[days][wClass] ?.[0].volume ? [this.state.summaryData[days][wClass] ?.[0].volume] : [0]
                            // columnPriceTotal[wClass] = this.state.summaryData[days][wClass]?.[0].price ? [this.state.summaryData[days][wClass]?.[0].price] : [0]
                        }
                        else {
                            this.state.summaryData[days][wClass] ?.[0].volume ? columTotal[wClass].push(this.state.summaryData[days][wClass] ?.[0].volume) : columTotal[wClass].push(0)
                            // this.state.summaryData[days][wClass]?.[0].price ? columnPriceTotal[wClass].push(this.state.summaryData[days][wClass]?.[0].price) : columnPriceTotal[wClass].push(0)
                        }
                        this.totalValue = columTotal;
                        // this.totalPrice = columnPriceTotal;
                    })
                })


                this.weightClass.map((weight, index) => {
                    this.totalValue[weight] = this.totalValue[weight].reduce(function (a, b) {
                        return (a + b)
                    }, 0)
                    // this.totalPrice[weight] = this.totalPrice[weight].reduce(function (a, b) {
                    //     return (a + b)
                    // }, 0)
                })
                this.forceUpdate()
            })

            .catch((error) => {
                console.log(error)
            })
    }
    confirmOrder() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.token}`
        }
        let webApiUrl = '/accumulator/api/quotee/' + localStorage.requestQuoteId + '/place-order/';
        var data = {
            "qoute_id": localStorage.requestQuoteId
        }
        axios.post(webApiUrl, data, {
            headers: headers
        })
            .then(response => {
                this.setState({
                    showQuoteConfirmModal: !this.state.showQuoteConfirmModal
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    confirmedQuote = () => {
        this.setState({
            showQuoteConfirmModal: !this.state.showQuoteConfirmModal
        })
        window.location.href = '/home';
    }



    render() {
        var daysOrder = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        return (
            <Aux>
                {
                    this.state.showQuoteConfirmModal && <Modal>
                        <div className={'pop_logo' + ' ' + 'hook'}></div>
                        <p>Kindly note that we have received your Order.<br /> You will receive a notification once the Order is confirmed.</p>
                        <div className={'modal_btn'} onClick={(event) => this.confirmedQuote()}>Okay</div>
                    </Modal>
                }

                <section className={'body_wrap'}>
                    <Sidebar />
                    <div className={'cont_wp'}>
                        {
                            this.state.quoteSummary ?.status !== 3 ? <Link to={'/home/request'}>
                                <p className={'Back_link'}>Back</p>
                            </Link> : <Link to={'/home/'}>
                                    <p className={'Back_link'}>Back</p>
                                </Link>
                        }
                        <section className={'cmn_head'}>
                            <p>Place Order</p>
                        </section>

                        <div className={"scrl_out"}>
                            <div className={'Modify_top'}>
                                <section>
                                    <div className={'mn_wr'}>
                                        <p className={'Cmn_in_head'}>
                                            Quote <span>( {this.state.quoteSummary ?.id} )</span>
                                        </p>

                                    </div>

                                    <div className={'ReqBtnWrp'}>
                                        {
                                            this.state.quoteSummary ?.status !== 3 ? <div className={'conBt' + ' ' + 'sp_btn'} onClick={(event) => this.confirmOrder()}>
                                                Place Order
                                        </div> : <div className={'conBt' + ' ' + 'sp_btn' + ' ' + 'disable'}>
                                                    Place Order
                                        </div>
                                        }
                                    </div>

                                </section>
                                <section>
                                    <div className={'Modify_lab_wrap'}>
                                        <div className={'mod_lab_out'}>
                                            <div className={'mod_lab'}>
                                                {this.state.quoteSummary ?.total_volume > 0 ? this.state.quoteSummary ?.total_volume : 0} {this.state.quoteSummary ?.total_volume == 1 ? 'Box' : 'Boxes'}
                                            </div>
                                            <p>Order Total Box</p>
                                        </div>
                                        <div className={'mod_lab_out'}>
                                            <div className={'mod_lab'}>
                                                {this.state.quoteSummary ?.total_volume > 0 ? (this.state.quoteSummary ?.total_weight.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') ) : 0} kg
                                            </div>
                                            <p>Order Total Weight</p>
                                        </div>
                                        <div className={'mod_lab_out'}>
                                            <div className={'mod_lab'}>
                                                $ {this.state.quoteSummary ?.quotee_total > 0 ? this.state.quoteSummary ?.quotee_total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : 0}
                                            </div>
                                            <p>Order Total USD</p>
                                        </div>
                                        <div className={'mod_lab_out'}>
                                            {
                                                <div className={'mod_lab'}>
                                                    {this.state.quoteSummary ?.shipment_week}
                                                </div>
                                            }
                                            <p>Shipment Week</p>
                                        </div>
                                        <div className={'mod_lab_out'}>
                                            {
                                                <div className={'mod_lab'}>
                                                    {this.state.quoteSummary ?.quote_week}
                                                </div>
                                            }
                                            <p>Order Week</p>
                                        </div>
                                        <div className={'mod_lab_out'}>
                                            {
                                                <div className={'mod_lab'}>

                                                </div>
                                            }
                                            <p></p>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <section className={'Summary_out'}>
                                {
                                    this.state.onDiscussionPrices.length != 0 && <p className={'note_p'}><span>Note : </span> Ongoing price discussions are stopped and {this.state.onDiscussionPrices.length > 1 ? "weight classes" : "weight class"}
                                        {
                                            this.state.onDiscussionPrices.map((data) => {
                                                return <label> {data}Kg, </label>
                                            })
                                        } {this.state.onDiscussionPrices.length > 1 ? "are" : "is"} removed from the order.</p>
                                }
                                <section className={'Summary_wrap'}>
                                    <div className={'Summary_row' + ' ' + 'header_row'}>
                                        <div className={'Summary_col' + ' ' + 'Summary_col_day'}>

                                        </div>
                                        {
                                            this.weightClass.map((weight, i) => {
                                                return <div className={'Summary_col'}>
                                                    {weight} Kg
                                                </div>
                                            })
                                        }
                                    </div>
                                    {
                                        this.state.shipmentMatrix.map((day, i) => {
                                            return <div className={'Summary_row'}>
                                                <div className={'Summary_col' + ' ' + 'Summary_col_day'}>

                                                    {daysOrder[day.date]} <span>
                                                        <Moment format="MMM DD">
                                                            {this.weekDet[day.date - 1]}
                                                        </Moment>
                                                    </span>
                                                </div>
                                                {
                                                    day.list.map((ship, i) => {
                                                        return <div className={'Summary_col'}>
                                                            {
                                                                ship.volume ? ship.volume : "-"
                                                            }
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        })
                                    }

                                    <div className={'Summary_row' + ' ' + 'footer_total'}>
                                        <div className={'Summary_col' + ' ' + 'Summary_col_day'}>
                                            Total Box
                                    </div>
                                        {
                                            this.weightClass.map((weight, i) => {
                                                return <div className={'Summary_col'}>
                                                    {this.totalValue[weight]}
                                                </div>
                                            })
                                        }
                                    </div>

                                    <div className={'Summary_row' + ' ' + 'footer_total'}>
                                        <div className={'Summary_col' + ' ' + 'Summary_col_day'}>
                                            Price/Kg
                                    </div>
                                        {
                                            this.weightClass.map((weight, i) => {
                                                return <div className={'Summary_col'}>
                                                    {this.state.perKgPrice[weight] ?.toFixed(2)}
                                                </div>
                                            })
                                        }
                                    </div>

                                </section>
                            </section>
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
};

export default OrderSummary;